import React from "react";
import { AppContext } from "../../../Context/Context";
import Button from "../../../Components/Button/Button";
import { authentication } from "../../../Api/authentication";
import Logo from "../../../Assets/Images/logo.png";
import "./../../../Components/Input/PhoneNumberInput/style.css";
import "./style.css";
import { CARL_LINK } from "../../../Constant/onboardingData";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";

const NumberVarification = ({
  handleToUpdate,
  setIsEmail,
  setExistingEmail,
}: {
  handleToUpdate: (val: boolean) => void;
  setIsEmail: (email: boolean) => void;
  setExistingEmail: (email: string) => void;
}) => {
  const {
    phone_Number,
    set_phone_number,
    setUserId,
    isUSResident,
    loginEmail,
    setIsExistingUser,
  } = React.useContext(AppContext);

  const [error, setError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [isInvalidEmail, setisInvalidEmail] = React.useState(false);
  const [errorSWW, setErrorSWW] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const phone_number = phone_Number.replace(/[^+0-9]/g, "");
  const checkNumber = parsePhoneNumberFromString(phone_number);
  const { setusResident } = React.useContext(AppContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formatter = new AsYouType();
    const orig = formatter.input(`${e.target.value}`.replace(/[^+0-9]/g, ""));
    let customFormatted = orig.replace(" ", " | ");
    set_phone_number(customFormatted);
  };
  const loginHandler = async () => {
    try {
      const response = await authentication.signIn(phone_number, loginEmail);
      if (response.status) {
        setIsEmail(response.message.to_email);
        setExistingEmail(response.message.email);
        set_phone_number(response.message.phone_number);
        setUserId(response.message.id);
        localStorage.setItem("userId", JSON.stringify(response.message.id));
        setLoading(false);
      }
      setErrorSWW(true);
    } catch {
      setLoading(true);
    }
  };
  const Submit = async () => {
    setEmailError(false);
    setisInvalidEmail(false);
    setError(false);
    setErrorSWW(false);
    setLoading(true);

    if (checkNumber.country === "US") {
      setusResident(true);
      localStorage.setItem("usResident", JSON.stringify(true));
    } else {
      setusResident(false);
      localStorage.setItem("usResident", JSON.stringify(false));
    }
    if (!checkNumber.isValid()) {
      setError(true);
      setLoading(false);
      return;
    }
    const res = await checkExistingUser(checkNumber.number);
    if (res.message.existing_user) {
      loginHandler();
      handleToUpdate(res?.message?.existing_user);
      setIsExistingUser(res?.message?.existing_user);
    } else {
      handleToUpdate(res?.message?.existing_user);
    }
    if (isUSResident) {
      return loginHandler();
    }
  };

  const checkExistingUser = async (phone_number: string) => {
    const response = await authentication.existingUser(phone_number);
    return response;
  };

  return (
    <div className="container">
      <div className="header">
        <div>
          <div className="verificationHeader">
            <img src={Logo} alt="logo" />
            <h5 className="mainTitle">Welcome To CARL</h5>
          </div>
          <div>
            <h6 className="title">Enter your mobile number to continue</h6>
          </div>
          <div>
            <h6 className="titleNumber">Mobile number</h6>
          </div>
          <input
            type="text"
            maxLength={17}
            // mask={isUSResident ? "+9 999 999 9999" : "+99 999 99 99999"}
            // mask=""
            className="Input"
            // maskChar={null}
            // onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            //   set_phone_number(e.target.value);
            // }}
            onChange={handleChange}
            value={phone_Number}
          />
        </div>
        {error ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Please ensure that you enter a correct phone number along with a
            valid country code.
          </h6>
        ) : emailError ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            {/* You are a new user please enter email address too. */}
          </h6>
        ) : isInvalidEmail ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Please enter a valid email address.
          </h6>
        ) : errorSWW ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Something went wrong. Please try again.
          </h6>
        ) : null}
        <div className="continueBtn">
          <Button
            label="Continue"
            className="buttonContinue"
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            disabled={loading}
            onClick={Submit}
          />
        </div>
        <div>
          <p className="footer">
            Trouble logging in ? Email us at
            <span className="footerSpan">
              <a
                className="termLink"
                href={`mailto:${CARL_LINK}`}
                target="_blank"
                rel="noreferrer"
              >
                {CARL_LINK}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NumberVarification;
