import { CategoryTitle } from "../../../Constant/onboardingData";
import ArrowForward from "../../../Assets/svg/arrowforward";
import "./style.css";
import { useHistory } from "react-router-dom";

type Props = {
  title: string;
  id: number;
};

const Documents = ({ handleToUpdate }: { handleToUpdate: (v: Props) => void }) => {
  const history = useHistory();

  return (
    <div className="container">
      <div className="header">
        <div className="mainheadertitle">
          <h4 className="title">Select one of the following identification documents</h4>
        </div>
        
        {CategoryTitle.map((value, index) => (
          <div
            onClick={() => handleToUpdate({ title: value.title, id: value.id })}
            key={index}
            className="titleContainer"
          >
            <h5 className="titleText">{value.title}</h5>
            <ArrowForward />
          </div>
        ))}

        <div className="announcementTitleContainer">
          <p style={{ textAlign: "left" }}>
            Once we have received your documents, a member of our team may contact you to provide additional evidence as appropriate.
          </p>
          
          <div
            className="announcementTitle"
            onClick={() => history.push('/', { step: 1 })}
          >
            * I do not have a copy of my identification documents available. I will complete my application later.
          </div>
        </div>
        
        <div>
          <p className="titleText">
            * Your ID selection must match the Investor Details you have provided.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Documents;
