import React from "react";
import { isMobile } from "react-device-detect";

import { KYC, Address, Business } from "../../../Api/onBoarding";
import { BackArrow } from "../../../Assets/svg/BackArrow";
import Button from "../../../Components/Button/Button";
import MobileComponent from "../../../Components/Files/MobileView";
import PassportCapture from "../../../Components/Files/Passport";
import { AppContext } from "../../../Context/Context";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import ShowCapturedImages from "../../../Components/Files/ShowCapturedImage";
type props = {
  title?: string;
  id?: number;
};

interface UploadDocumentsProps {
  handleToUpdate: () => void;
  documentTitle: props;
  setcurrentStep: any;
  isAddr: boolean;
  isbusiness?: boolean;
}

function UploadDocuments({
  handleToUpdate,
  documentTitle,
  setcurrentStep,
  isAddr,
  isbusiness,
}: UploadDocumentsProps) {
  const [frontImage, setFrontImage] = React.useState<File | null>(null);
  const [backImage, setBackImage] = React.useState<File | null>(null);
  const [flipSide, setFlipSide] = React.useState("front");
  const [loading, setLoading] = React.useState(false);
  let { userId, investorType } = React.useContext(AppContext);

  const { title: documentName, id: documentType } = documentTitle;
  const isInputEmpty =
    documentName === "Passport" ||
    documentName === "Address Proof" 
  //  || documentName === "Business Proof"
      ? frontImage === null
      : frontImage === null || backImage === null;

  const retakeImage = () => {
    setFrontImage(null);
    setBackImage(null);
    setFlipSide("front");
  };

  const goBack = () => {
    setcurrentStep(1);
  };

  const submitForm = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      userId,
      documentType,
      frontImage,
      backImage,
    };

    

    if (isAddr) {
      console.log("in adrre block");
      console.log(frontImage, "this is image")
      try {
        const res = await Address.submitDoc(data);
        if (res.data.success) {
          handleToUpdate();
          localStorage.setItem("img", res.data.address_proof_url);
          console.log( res.data, "actual image")
        } else {
          setLoading(true);
        }
      } catch (error) {
        setLoading(true);
      }
    } 
    // else if (isbusiness) {
    //   handleToUpdate();
    //   // console.log("in busniess block");
    //   try {
    //     const res = await Business.businessDoc(data);
    //     if (res.data.success) {
    //       handleToUpdate();
    //       localStorage.setItem("img",res.data.address_proof_url)
    //     } else {
    //       setLoading(true);
    //     }
    //   } catch (error) {
    //     setLoading(true);
    //   }
    // } 
    else {
      try {
        const res = await KYC.submit(data);
        if (res.data.success) {
          handleToUpdate();
        } else {
          setLoading(true);
        }
      } catch (error) {
        setLoading(true);
      }
    }
  };

  return (
    <div className="container">
      <div className="documentContainer">
        <div className="topContainer">
          <div className="headerWithBack">
            <BackArrow onClick={goBack} className="mt-1" />
            <h1 className="title">{`Upload your ${
              documentName === "Address Proof"
                ? investorType === "1" || investorType === "2"
                  ? "Entity Address Verification Document"
                  : "Address Verification Document"
                : documentName 
                // === "Business Prrof"
                // ? "Upload a document that shows you are legally authorized to make decisions for the company."
                // : documentName
            }`}</h1>
          </div>

            <div className="text-center">
            {documentName === "Address Proof"&&<p style={{color:'#D819FF'}}>Example: utility bill or bank statement.</p>}
            </div>
          {documentName === "Passport" ||
          documentName === "Address Proof" ||
          documentName === "Business Proof" ? (
            <PassportCapture
              frontImage={frontImage}
              setFrontImage={setFrontImage}
              retakeImage={retakeImage}
            />
          ) : (
            <MobileComponent
              frontImage={frontImage}
              backImage={backImage}
              flipSide={flipSide}
              setFrontImage={setFrontImage}
              retakeImage={retakeImage}
              setBackImage={setBackImage}
            />
          )}
        </div>
        <PrivacyContainer />
        <div className={!isMobile ? "button-cont-text" : ""}>
          <Button
            label="Continue"
            className={isInputEmpty ? "disableButton" : "buttonContinue"}
            disabled={isInputEmpty}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            onClick={submitForm}
          />
        </div>
      </div>
    </div>
  );
}

export default UploadDocuments;
