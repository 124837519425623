import React from "react";

import MainStepper from "../../../Components/Stepper/MainStepper";
import VerifiAddressScreen from "../../Onboarding/AddressScreens/VerifiAddressScreen";
import AddressInformation from "../../Onboarding/AddressScreens/AddressDetails";
import SuccessScreen from "../../../Components/SuccessScreen/SuccessScreen";
import { Routes } from "../../../Constant/Routes/Routes";
import { AppContext } from "../../../Context/Context";
import { INVESTOR_TYPE } from "../../../Constant/onboardingData";
import UploadDocuments from "../../Onboarding/IdentificationScreens/UploadDocuments";
import Preview from "../../Onboarding/AddressScreens/Preview";

type props = {
  title?: string;
  id?: number;
};
// type value = {
//   val?: string
// };

const arraySteps = [
  { title: "Verify Address" },
  { title: "Address Details" },
  { title: "Upload Documents" },
  { title: "Preview" },
  { title: "Success" },
];

const AddressStepper = () => {
  const [currentStep, setcurrentStep] = React.useState(0);
  const [title, settitle] = React.useState<props>({
    title: "Address Proof",
    id: 1,
  });

  const { firstName, email, investorType } = React.useContext(AppContext);
  const handleToUpdate = (val: any) => {
    if (val == "back") {
      setcurrentStep(currentStep - 1);
    } else {
      setcurrentStep(currentStep + 1);
    }
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return (
          <VerifiAddressScreen
            handleToUpdate={() => setcurrentStep(currentStep + 1)}
          />
        );
      case 1:
        return (
          <AddressInformation
            handleToUpdate={() => setcurrentStep(currentStep + 1)}
          />
        );
      case 2:
        return (
          <UploadDocuments
            handleToUpdate={() => setcurrentStep(currentStep + 1)}
            documentTitle={title}
            setcurrentStep={setcurrentStep}
            isAddr={true}
          />
        );
      case 3:
        return <Preview handleToUpdate={handleToUpdate} />;
      case 4:
        return (
          <SuccessScreen
            title="Success"
            heading="We have recieved your address details"
            navigate={Routes.IDENTIFICATION}
          />
        );
      // return investorType === INVESTOR_TYPE["Non US Entity"] ||
      //   investorType === INVESTOR_TYPE["Non US Individual"] ? (
      //   <SuccessScreen
      //     title="Success"
      //     heading={`Thank you, ${firstName}. We will email you to schedule a time to speak.`}
      //     button="Visible"
      //   />
      // ) : (
      //   <SuccessScreen
      //     title="Success"
      //     heading="We have recieved your address details"
      //     navigate={Routes.IDENTIFICATION}
      //   />
      // );
      default:
        return null;
    }
  };

  return (
    <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
      {renderComponent()}
    </MainStepper>
  );
};

export default AddressStepper;
