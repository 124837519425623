import appJSON from "../../package.json";

export const InvestAmount = [
  {
    id: 1,
    discripton:
      "My gross income was more than $200,000 OR my joint income with my spouse was more than $300,000 in each of the two most recent years, and I have a reasonable expectation of reaching the same income level in the current year",
  },
  {
    id: 2,
    discripton:
      "My individual net worth OR joint net worth with my spouse exceeds $1,000,000 (excluding the value of my primary residence)",
  },
  { id: 3, discripton: "I qualify based on other criteria." },
];

export const EntityInvestmentAmount = [
  {
    id: 1,
    discripton:
      " I invest on behalf of an entity with more than $5M in assets.",
  },
  {
    id: 2,
    discripton:
      "The entity consists of equity owners who are all accredited investors.",
  },
  { id: 3, discripton: "The entity qualifies based on other criteria." },
];

export const CategoryTitle = [
  {
    id: 1,
    title: "Passport",
  },
  {
    id: 2,
    title: "Driver's Licence",
  },
  {
    id: 3,
    title: "Identity Card",
  },
];

export const Status = [
  { id: "", name: "Select One" },
  { id: "Alabama", name: "Alabama" },
  { id: "Arizona", name: "Arizona" },
  { id: "EntAlaskaity", name: "EntAlaskaity" },
  { id: "Arkansas", name: "Arkansas" },
];

export const InvestorType = [
  { id: 1, name: "Entity Investor" },
  { id: 0, name: "Individual Investor" },
];

export const NonUSInvestorType = [
  { id: 4, name: "Individual Investor" },
  { id: 2, name: "Entity Investor" },
];

export const INVESTOR_TYPE = {
  Entity: "1",
  Individual: "0",
  "Non US Individual": "4",
  "Non US Entity": "2",
};

export const ApplicationStatus = {
  NOT_STARTED: "NOT STARTED", /// New user
  ONBOARDING_PENDING: "ONBOARDING PENDING", ///user kill tha app during onboarding
  PENDING: "IN PROCESS", ////when unboarding done
  RESUBMIT: "RESUBMIT", ///reject onbording
  APPROVED: "APPROVED", ///user approved
};
export const CARL_LINK = "ir@investwithcarl.com";

export const device_id = "9d3bd076-9f71-477a-8612-c4dd62d0919d";
export const device_info = "Web";
export const push_token = "";
export const platform = "Web";
export const versionCode = appJSON.versionCode;
export const version = appJSON.version;

export const onboardingApi = "3.129.36.235";
