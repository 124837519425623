import React from "react";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import Button from "../../../Components/Button/Button";
import Accredited from "../../../Assets/Images/Accredited.svg"
function IntroScreen({ handleToUpdate }: { handleToUpdate: () => void }) {
  return (
    <div>
      <div className="container">
        <div className="header">
        <div className="logoContainer">
          <img src={Accredited} alt="logo" />{" "}
        </div>
          <div className="titleContainerSuccess">
            <h4 className="title">
              Next, we need to verify your Accredited Status
            </h4>
          </div>
          <PrivacyContainer />
          <Button
            label="Continue"
            className="buttonContinue"
            onClick={handleToUpdate}
          />
        </div>
      </div>
    </div>
  );
}

export default IntroScreen;
