import React, { useCallback, useMemo, useState } from "react";
import { AppContext } from "../../../Context/Context";

import Button from "../../../Components/Button/Button";
import {
  InvestorType,
  INVESTOR_TYPE,
  NonUSInvestorType,
} from "../../../Constant/onboardingData";
import SelectDropDown from "../../../Components/SelectDropdown/SelectDropDown";
import InvestorWorth from "../../../Components/investorCategory/InvestorCategorySelect";
import { Info } from "../../../Api/onBoarding";
import NonUsEntity from "../../../Components/NonUsInfo/NonUsEntity";
import NonUsIndividual from "../../../Components/NonUsInfo/NonUsIndividual";
import "./perfsonalInfoStyle.css";
import USEntitySplash from "../../../Components/NonUsInfo/USEntitySplash";
import NONUSSplash from "../../../Components/NonUsInfo/NonUsSplash";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import EntitycategorySelect from "../../../Components/investorCategory/EntitycategorySelect";

function InvestorCategory({ handleToUpdate }: { handleToUpdate: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [dropdownDisable, setdropdownDisable] = React.useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const [errMsg, setErrMsg] = React.useState("");
  const [flag, setFlag] = React.useState(true);

  
  const {
    phone_Number,
    investorOptionSelect,
    firstName,
    lastName,
    // email,
    loginEmail,
    company,
    investorType,
    setinvestorType,
    isUSResident,
    userId,
    einNumber,
  } = React.useContext(AppContext); 
  console.log("investorType",investorOptionSelect,investorType,InvestorType);



  React.useEffect(() => {
    if(investorType == "4"){
      setFlag(false);
    }
    else{
      setFlag(true);
      
    }
    console.log(InvestorType, "investor")
    }, [investorType])
    
  
  const email=loginEmail
  const entityUser = firstName && lastName && email && company ? true : false;
  const individualUser = firstName && lastName && email ? true : false;

  const result = useMemo(() => {
    if (isUSResident) {
      if (investorType === INVESTOR_TYPE.Individual && investorOptionSelect)
        return true;
        // if (investorType === INVESTOR_TYPE.Entity && investorOptionSelect && entityUser && einNumber)
        // return true;
      if (investorType === INVESTOR_TYPE.Entity && investorOptionSelect )
        return true;
    }
    if (!isUSResident) {
      if (investorType === INVESTOR_TYPE["Non US Entity"] && entityUser)
        return true;
      if (investorType === INVESTOR_TYPE["Non US Individual"] && individualUser)
        return true;
    } else {
      return false;
    }
  }, [
    isUSResident,
    investorType,
    entityUser,
    individualUser,
    investorOptionSelect,
    einNumber,
  ]);

  const InvestorTypeCheck = useCallback(async () => {
    if (investorType) {
      try {
        await Info.setInvestorType(+investorType, phone_Number, userId);
      } catch {
        setLoading(false);
      }
    }
  }, [investorType, phone_Number, userId]);

  React.useEffect(() => {
    InvestorTypeCheck();
    setError(false);
    return () => {
      setShowSplash(true);
    };
  }, [InvestorTypeCheck]);

  const apiResponse = (response: any) => {
    if (response.status) {
      if (response.message.success) {
        handleToUpdate();
      }
    } else {
      setError(true);
      const errorMessage = Object.values(response.message).join();
      setErrMsg(errorMessage);
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    setFlag(false)
    setdropdownDisable(true);
    setLoading(true);
    try {
      if (isUSResident) {
        if (investorType === INVESTOR_TYPE.Entity) {
          const response = await Info.submitEntityForm({
            company,
            email,
            firstName,
            lastName,
            userId,
            phone_Number,
            einNumber,
          });
          apiResponse(response);
        }
          const response = await Info.submitIndividualForm(
            userId,
            investorOptionSelect
          );
          apiResponse(response);
      }
      if (!isUSResident) {
        if (investorType === INVESTOR_TYPE["Non US Entity"]) {
          const response = await Info.submitEntityForm({
            company,
            email,
            firstName,
            lastName,
            userId,
            phone_Number,
          });
          apiResponse(response);
        }
        if (investorType === INVESTOR_TYPE["Non US Individual"]) {
          const response = await Info.submitIndividualFormNoNUS({
            email,
            firstName,
            lastName,
            userId,
          });
          apiResponse(response);
        }
      }
    } catch {
      setLoading(true);
    }
  };
  const renderData = () => {
    if (isUSResident) {
      if (investorType === INVESTOR_TYPE.Individual) {
        return showSplash ? <NONUSSplash isUS={true} /> : <InvestorWorth />;
        setFlag(false);
      } else if (investorType === INVESTOR_TYPE.Entity) {
        return showSplash ? <USEntitySplash /> : <EntitycategorySelect />;
      }
    } else if (!isUSResident) {
      if (investorType === INVESTOR_TYPE["Non US Entity"]) {
        return showSplash ? <USEntitySplash /> : <NonUsEntity handleToUpdate={handleToUpdate} />;
      } else if (investorType === INVESTOR_TYPE["Non US Individual"]) {
        return showSplash ? <NONUSSplash /> : <NonUsIndividual />;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div className="header">
        <div className="title">
          <h3 className="title">What type of investor are you?</h3>
        </div>
        <div style={{ marginTop: 20 }}>
          <SelectDropDown
            items={isUSResident ? InvestorType : NonUSInvestorType}
            selected={investorType}
            captionKey="name"
            className="inputField"
            handler={setinvestorType}
            disable={dropdownDisable}
          />
          <br />
          <br />
          {flag && <p className="verif_text">*Choose “Individual” if you plan to invest with a retirement account.</p>}
        </div>
        {renderData()}
        <div>
          <h6 style={{ color: "#ff4d4d", marginTop: 20, textAlign: "center" }}>
            {" "}
            {error ? `* ${errMsg}` : null}
          </h6>{" "}
        </div>
        {showSplash && !!investorType ? <PrivacyContainer /> : null}
        <div>
          {showSplash && !!investorType ? (
            <Button
              label="Start Application"
              className="buttonContinue"
              onClick={() => setShowSplash(false)}
            />
          ) : (
            <Button
              label="Continue"
              className={result ? "buttonContinue" : "deactiveButton"}
              icon={
                loading ? (
                  <i className="fas fa-circle-notch fa-spin mt-2"></i>
                ) : null
              }
              disabled={!result}
              onClick={submitHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default InvestorCategory;
