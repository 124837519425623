import React from "react";
import "../AccreditedInvestor/index.css";
import PassportCapture from "../../../Components/Files/Passport";
import Button from "../../../Components/Button/Button";
import File from "../../../Assets/Images/file.png";
import Failed from "../../../Assets/Images/failed.png";
import Success from "../../../Assets/Images/success.png";
import { Business } from "../../../Api/onBoarding";
interface dataImages {
  fname: string;
  fimage: File | null;
}
function Upload({ handleToUpdate }: { handleToUpdate: () => void }) {
  const [frontImage, setFrontImage] = React.useState<File | null>(null);
  const [fileName, setFileName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const retakeImage = () => {
    setFrontImage(null);
  };
  const handleFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  };

  async function handleContinue() {
    setLoading(true)
    const res = await Business.businessDoc(fileName, frontImage);
    console.log(res);
    if (res) {
    handleToUpdate();
    setLoading(false)
    }
  }

  return (
    <div className="container">
      <div className="documentContainer">
        <div className="topContainer">
          <div className="headerWithBack">
            <h1 className="title">
              Upload a document that shows you are legally authorized to make
              decisions for the company.
            </h1>
          </div>
          {/* <p className="verif_text"> Example: brokerage statement or bank statement.</p> */}

          <div className="img_container">
            <div className="btn_textbox">
              <input
                style={{ padding: "10px" }}
                type="text"
                name=""
                value={fileName}
                placeholder="File Name / Title"
                onChange={handleFileName}
              />
            </div>
            <PassportCapture
              frontImage={frontImage}
              setFrontImage={setFrontImage}
              retakeImage={retakeImage}
            />
          </div>
          {/* <div className="docs_list">
Documents List
<hr />
{name_img.map((obj,index) => (
<div key={index} className="fileSection">
<img src={File} />
<span>{obj.fname}</span>
<img src={Success} />
</div>
))}
</div> */}
          <Button
          disabled={!fileName && !frontImage}
          icon={
            loading ? (
              <i className="fas fa-circle-notch fa-spin mt-2"></i>
            ) : null
          }
            label="Continue"
            className={(!fileName && !frontImage)? "disableButton" : "buttonContinue"}
            onClick={handleContinue}
          />
        </div>
      </div>
    </div>
  );
}

export default Upload;
