import React from "react";
import "./index.css";
import PassportCapture from "../../../Components/Files/Passport";
import Button from "../../../Components/Button/Button";
import File from "../../../Assets/Images/file.png";
import Failed from "../../../Assets/Images/failed.png";
import { Accreditation } from "../../../Api/onBoarding";
interface dataImages {
  fname: string;
  fimage: File | null;
}
function AccreditedVerification({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) {
  const [frontImage, setFrontImage] = React.useState<File | null>(null);
  const [fileName, setFileName] = React.useState("");
  const [name_img, setname_img] = React.useState<dataImages[]>([]);
  const [loading, setLoading] = React.useState(false);

  const retakeImage = () => {
    setFrontImage(null);
  };
  const handleFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  };

  const handleUpload = () => {
    if (name_img.length < 5) {
      const newObj = {
        fname: fileName,
        fimage: frontImage,
      };
      setname_img([...name_img, newObj]);
      setFileName("");
      setFrontImage(null);
    } else {
      console.log("You’ve reached the maximum limit of 5 documents")
    }
  };

  async function handleContinue() {
    setLoading(true)
    const data={name_img}
    const res = await Accreditation.post(data);
    handleToUpdate();
    setLoading(false);
  }
  const handleRemove = (index: number) => {
    const newList = name_img.filter((_, i) => i !== index);
    setname_img(newList);
  };  
  
  return (
    <div className="container">
      <div className="documentContainer">
        <div className="topContainer">
          <div className="headerWithBack">
            <h1 className="title">
              Upload a document that verifies you are an accredited investor
            </h1>
          </div>
          <p className="verif_text">
            {" "}
            Example: brokerage statement or bank statement.
          </p>

          <div className="img_container">
            <div className="btn_textbox">
              <input
                type="text"
                name=""
                value={fileName}
                placeholder="File Name / Title"
                onChange={handleFileName}
              />
              <Button
                disabled={!frontImage ||!fileName}
                className="upload_btn"
                label="Upload"
                onClick={handleUpload}
              />
            </div>
            <PassportCapture
              frontImage={frontImage}
              setFrontImage={setFrontImage}
              retakeImage={retakeImage}
            />
          </div>
          <div className="docs_list">
            Documents List
            <hr />
              {name_img.map((obj, index) => (
                <div key={index} className="fileSection">
                  <img src={File} alt=""/>
                  <span className="fs-5 mb-1">{obj.fname}</span>
                  <div className="remove">
                    <img src={Failed} onClick={() => handleRemove(index)} alt="" />
                  </div>
                </div>
              ))}
          </div>
          <Button
            disabled={name_img.length === 0 && name_img.length > 4}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            label="Continue"
            className={
              name_img.length === 0 ? "disableButton" : "buttonContinue"
            }
            onClick={handleContinue}
          />
        </div>
      </div>
    </div>
  );
}

export default AccreditedVerification;
