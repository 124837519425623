import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const PrivacyModal = ({ setShow, show }: any) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            How we protect your information?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title className="my-3 h6">
            How we protect your information and why we ask for it?
          </Modal.Title>
          First of all, we wouldn’t collect your personal information if we
          didn’t have to! And we will never share or sell your information. CARL
          collects information when you visit our website or mobile app, sign-up
          as an investor, make deposits or withdrawals, or otherwise engage with
          the CARL App. It is a requirement of federal securities law that we
          collect this data. Also, collecting various types of data helps us
          provide you with the highest quality service.
          <Modal.Title className="my-3 h6 ">
            What Information We Collect
          </Modal.Title>
          The types of personal information we collect can include: name, email
          address, mailing address, phone number, social security number,
          driver's license number, financial information such as bank account
          numbers, marketing preferences, and demographic information such as
          age, education, gender, and location.
          <Modal.Title className="my-3 h6">
            Why We Collect Your Personal Information
          </Modal.Title>
          We use your personal information for everyday business purposes such
          as to process your transactions and maintain your accounts. The
          information is also used to analyze usage and improve service, to send
          you administrative notices and alerts, for marketing purposes such as
          to offer products and services, for troubleshooting problems, and to
          detect and protect against error, fraud, or other criminal activity.
          <Modal.Title className="my-3 h6">
            How We Protect Your Personal Information
          </Modal.Title>
          CARL works diligently to protect your personal information from
          unauthorized access and use. We employ strong security measures that
          comply with federal law. These measures include computer safeguards
          and secured physical files and buildings. Third-party contractors that
          provide services to CARL are bound by these same privacy restrictions
          and if you choose to close your account with us, we will continue to
          adhere to the privacy policies and practices outlined here. We do not
          sell or share your data. We do not want your data and if it was not
          required by federal securities law, we would give the data back to
          you. <br />
          <br />
          For more information view our full{" "}
          <a
            href="https://investwithcarl.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="privacyText"
          >
            privacy policy
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="mx-auto px-5"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PrivacyModal;
