import React, { useState, ChangeEvent } from 'react';
import InputMask from "react-input-mask";
import InputField from "../Input/InputField/InputField";
import { AppContext } from "../../Context/Context";
import {
  NON_US_ENTITY_TITLE,
  NON_US_HEADING,
  FOOTER_MESSAGE,
} from "../../Constant/Content";
import "./NonUsFlow.css";
import { INVESTOR_TYPE } from "../../Constant/onboardingData";
import Button from "../../Components/Button/Button";
import { Info } from "../../Api/onBoarding";
const NonUsEntity = ({ handleToUpdate }: { handleToUpdate: () => void }) => {
  const { firstName, setFirstName } = React.useContext(AppContext);
  const { lastName, setLastName } = React.useContext(AppContext);
  const { company, setCompany } = React.useContext(AppContext);
  // const { email, setEmailAddress } = React.useContext(AppContext);
  const { setEinNumber } = React.useContext(AppContext);
  const { investorType, userId, einNumber, phone_Number, loginEmail } =
    React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const [SSN, setSSN] = React.useState("");
  const [firstNameValue, setFirstNameValue] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<boolean>(true);
  const [lastNameValue, setLastNameValue] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<boolean>(true);


  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const firstName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z]*$/; // Regular expression to match only alphabetic characters
    if (characterRegex.test(firstName)) {
      setFirstNameValue(firstName);
      setFirstName(firstName)
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const lastName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z]*$/; // Regular expression to match only alphabetic characters
    if (characterRegex.test(lastName)) {
      setLastNameValue(lastName);
      setLastName(lastName)
      setLastNameError(true);
      
    } else {
      setLastNameError(false);
    }
  };


  const isInputEmpty = firstNameValue && lastNameValue && company ? true : false;
  const email = loginEmail;
  const ssn = SSN.replace(/[^+0-9]/g, "");
  const handleSubmit = async () => {
    setLoading(true);
    const response = await Info.submitEntityForm({
      company,
      email,
      firstName,
      lastName,
      userId,
      phone_Number,
      einNumber,
      ssn
    });
    if (response.status && investorType=="1") {
      if (response.message.success) {
        handleToUpdate();
      }
    }
    
  };
  return (
    <div className={`${investorType == "1" && "container"}`}>
      <div className={`${investorType == "1" && "header"}`}>
        <div>
          {investorType == "1" &&<h4 className="nonUsTitle">{NON_US_ENTITY_TITLE}</h4>}
          <h3 className="title">{NON_US_HEADING}</h3>
          <InputField
            type="text"
            placeholder="First Name"
            name="firstName"
            value={firstName}
            onChange={handleFirstNameChange}
            className="inputField"
          />
          {!firstNameError && <p style={{color: 'red'}}>Input must contain only alphabetic characters.</p>}
          <InputField
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={lastNameValue}
            onChange={handleLastNameChange}
            className="inputField"
          />
          {!lastNameError && <p style={{color: 'red'}}>Input must contain only alphabetic characters.</p>}
          {investorType === INVESTOR_TYPE.Entity ? (
            <InputMask
            mask="999-99-9999"
            className="inputField"
            maskChar={null}
            onChange={(e) => setSSN(e.target.value)}
            placeholder="Social Security Number"
          />
          ) : null}
          <InputField
            type="text"
            placeholder="Company Name"
            name="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            className="inputField"
          />
          {/* <InputField
        type="text"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => setEmailAddress(e.target.value)}
        className="inputField"
      /> */}
          {investorType === INVESTOR_TYPE.Entity ? (
            <InputMask
              mask="999999999"
              className="inputField"
              maskChar={null}
              onChange={(e) => setEinNumber(e.target.value)}
              placeholder="Business EIN (must be 9 character)"
            />
          ) : null}
          <div>
            <p style={{ marginTop: 10 }}> {FOOTER_MESSAGE}</p>
          </div>
        </div>
        {investorType === "1" && (
          <div className="continueBtn">
            <Button
              label="Continue"
              className={isInputEmpty ? "buttonContinue" : "deactiveButton"}
              icon={
                loading ? (
                  <i className="fas fa-circle-notch fa-spin mt-2"></i>
                ) : null
              }
              disabled={!isInputEmpty || loading}
              onClick={handleSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NonUsEntity;