import { createContext } from "react";

export interface IAppContext {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  citizenship: string;
  investorType: string;
  investorOptionSelect: any;
  isUSResident: string;
  phone_Number: string;
  einNumber: string;
  loginEmail: string;
  userId: any;
  token: string | null;
  applicationStatus: string;
  isExistingUser:string;
  setusResident: (isUSResident: string | boolean) => void;
  set_phone_number: (phone_Number: string) => void;
  setUserId: (userId: number) => void;
  setToken: (token: string | null) => void;
  setinvestorOptionSelect: (investorOptionSelect: any) => void;
  setFirstName: (firstName: string) => void;
  setinvestorType: (investorType: string) => void;
  setLastName: (lastName: string) => void;
  setEmailAddress: (email: string) => void;
  setCompany: (company: string) => void;
  setCitizenship: (citizenship: string) => void;
  setApplicationStatus: (citizenship: string) => void;
  setEinNumber: (einNumber: string) => void;
  setLoginEmail: (loginEmail: string) => void;
  setIsExistingUser:(isExistingUser: string | boolean) => void;
}

export const AppContext = createContext<IAppContext>({
  investorOptionSelect: "",
  isUSResident: "",
  phone_Number: "",
  userId: "",
  token: "",
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  einNumber: "",
  loginEmail: "",
  citizenship: "",
  investorType: "",
  applicationStatus: "",
  isExistingUser:"",
  setusResident: () => {},
  set_phone_number: () => {},
  setUserId: () => {},
  setToken: () => {},
  setinvestorOptionSelect: () => {},
  setFirstName: () => {},
  setinvestorType: () => {},
  setLastName: () => {},
  setEmailAddress: () => {},
  setCompany: () => {},
  setCitizenship: () => {},
  setApplicationStatus: () => {},
  setEinNumber: () => {},
  setLoginEmail: () => {},
  setIsExistingUser:()=>{},
});
