import React from "react";
import InputMask from "react-input-mask";
import Button from "../../../Components/Button/Button";
import InputField from "../../../Components/Input/InputField/InputField";
import { Info } from "../../../Api/onBoarding";
import { AppContext } from "../../../Context/Context";
import "./perfsonalInfoStyle.css";
import Logo from "../../../Assets/Images/logo.png";
import dayjs from "dayjs";
import { DatePicker, DatePickerProps } from "antd";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";

export default function PersonalInfo({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) {
  const { firstName, setFirstName } = React.useContext(AppContext);
  const { lastName, setLastName } = React.useContext(AppContext);
  // const { email,loginEmail, setEmailAddress } = React.useContext(AppContext);
  const { loginEmail } = React.useContext(AppContext);
  const [dateOfBirth, setDateOfBirth] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | boolean>(false);
  const [SSN, setSSN] = React.useState("");

  const email=loginEmail
  const isInputEmpty =
    firstName && lastName && email && dateOfBirth && SSN ? true : false;

  const ssn = SSN.replace(/[^+0-9]/g, "");
  const newDate = dayjs();
  const eighteenYears = newDate.year() - 18;
  const birthDate1 = newDate.format(JSON.stringify(dateOfBirth));
  const minDate1 = newDate.year(eighteenYears).format();

  const handleSubmit = async () => {
    setError(false);
    const userDetails: any = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };
    localStorage.setItem("UserDetails", JSON.stringify(userDetails));

    setLoading(true);
    const userData = {
      first_name: firstName,
      last_name: lastName,
      email,
      dob: dateOfBirth,
      ssn,
      device_info: "Web",
    };
    if (dayjs(birthDate1) > dayjs(minDate1)) {
      setLoading(false);
      setError("You must be 18 years old");
    } else {
      try {
        const response = await Info.update(userData);
        if (response.status) {
          if (response.message.success === false) {
            setError(response.message.message);
            setLoading(false);
          } else {
            handleToUpdate();
          }
        } else {
          const parsedError = JSON.parse(response.message);
          const errorMessage = Object.values(parsedError).join();
          setError(errorMessage);
          setLoading(false);
        }
      } catch {
        setLoading(true);
      }
    }
  };

  const onChange: DatePickerProps["onChange"] = (_, dateString) => {
    setDateOfBirth(dateString);
  };

  const datePicker = () => {
    return (
      <DatePicker
        placeholder="Date of Birth"
        onChange={onChange}
        format="MM-DD-YYYY"
      />
    );
  };

  return (
    <div className="container">
      <div className="header">
        <div className="mainTitleContainer">
          <img src={Logo} width="60px" alt="Logo" />{" "}
          <span className="mainTitle">CARL</span>
        </div>
        <div className="mainheadingContainer">
          <h4 className="title">Investor Details</h4>
        </div>
        <div style={{ marginTop: 20 }}>
          <InputField
            type="text"
            placeholder="First Name"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="inputField"
          />
          <InputField
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="inputField"
          />
          {/* <InputField
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={(e) => setEmailAddress(e.target.value)}
            className="inputField"
          /> */}
          {datePicker()}
          <InputMask
            mask="999-99-9999"
            className="inputField"
            maskChar={null}
            onChange={(e) => setSSN(e.target.value)}
            placeholder="Social Security Number"
          />
        </div>

        <div>
          <h6 style={{ color: "red", marginTop: 20, textAlign: "center" }}>
            {error ? `* ${error}` : null}
          </h6>
        </div>
        <PrivacyContainer />
        <div className="continueBtn">
          <Button
            label="Continue"
            className={isInputEmpty ? "buttonContinue" : "deactiveButton"}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            disabled={!isInputEmpty || loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
