import * as React from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import InvestorCategory from "./investorCategory";
import PersonalInfo from "./PersonalInfo";
import MessageScreen from "../../../Components/SuccessScreen/SuccessScreen";
import { AppContext } from "../../../Context/Context";
import { INVESTOR_TYPE } from "../../../Constant/onboardingData";
import { Routes } from "../../../Constant/Routes/Routes";
import NonUsEntity from "../../../Components/NonUsInfo/NonUsEntity";
const PersonalInfoScreen = () => {
  const [currentStep, setcurrentStep] = React.useState(0);
  const { isUSResident, investorType } = React.useContext(AppContext);
  const userType = investorType !== INVESTOR_TYPE.Entity;
  const arraySteps = [
    { title: "Investor Category" },
    isUSResident ? { title: "Personal Info" } : { title: "" },
    { title: "Success" },
  ];

  const handleToUpdate = () => {
    console.log("isUSResident",isUSResident);
    
    isUSResident &&INVESTOR_TYPE.Entity
      ? setcurrentStep(currentStep + 1)
      : setcurrentStep(currentStep + 2);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <InvestorCategory handleToUpdate={handleToUpdate} />;
      case 1:
        return investorType !== "1" && investorType !== "4" ? (
          <PersonalInfo handleToUpdate={handleToUpdate} />
        ) : (
          <NonUsEntity handleToUpdate={()=>setcurrentStep(currentStep + 1)} />
        );
      // case 2:
      //   return <NonUsEntity handleToUpdate={handleToUpdate}/>;
      case 2:
        return (
          <MessageScreen
            title="Success"
            heading="We have recieved your Investor details"
            navigate={Routes.ADDRESS_VERIFICATION}
          />
        );
      // case 3:
      //   return (
      //     <MessageScreen
      //       title="Success"
      //       heading="We have recieved your Investor Details"
      //       navigate={Routes.ADDRESS_VERIFICATION}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
      {renderComponent()}
    </MainStepper>
  );
};

export default PersonalInfoScreen;
