import React, { useState, ChangeEvent } from 'react';
import InputField from "../Input/InputField/InputField";
import { AppContext } from "../../Context/Context";
import {
  NON_US_INDIVIDUAL_TITLE,
  NON_US_INDIVIDUAL_HEADING,
  NON_US_HEADING,
  FOOTER_MESSAGE,
} from "../../Constant/Content";
import "./NonUsFlow.css";
import { first } from 'lodash';

const NonUsIndividual = () => {
  const { firstName, setFirstName } = React.useContext(AppContext);
  // const { email, setEmailAddress } = React.useContext(AppContext);
  const { lastName, setLastName } = React.useContext(AppContext);
  const [firstNameValue, setFirstNameValue] = useState<string>('');
  const [firstNameValid, setFirstNameValid] = useState<boolean>(true);
  const [lastNameValue, setLastNameValue] = useState<string>('');
  const [lastNameValid, setLastNameValid] = useState<boolean>(true);


  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const firstName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z]*$/; // Regular expression to match only alphabetic characters
    if (characterRegex.test(firstName)) {
      setFirstNameValue(firstName);
      setFirstName(firstName)
      setFirstNameValid(true);
    } else {
      setFirstNameValid(false);
    }
  };
  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const lastName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z]*$/; // Regular expression to match only alphabetic characters
    if (characterRegex.test(lastName)) {
      setLastNameValue(lastName);
      setLastName(lastName)
      setLastNameValid(true);
    } else {
      setLastNameValid(false);
    }
  };

  return (
    <div>
      {" "}
      <h4 className="nonUsTitle">{NON_US_INDIVIDUAL_TITLE}</h4>
      <h6 className="nonUsHeading">{NON_US_INDIVIDUAL_HEADING}</h6>
      <h3 className="title">{NON_US_HEADING} </h3>
      <InputField
        type="text"
        placeholder="First Name"
        name="firstName"
        value={firstName}
        onChange={handleFirstNameChange}
        className="inputField"
      />
      {!firstNameValid && <p style={{color: 'red'}}>Input must contain only alphabetic characters.</p>}
      <InputField
        type="text"
        placeholder="Last Name"
        name="lastName"
        value={lastNameValue}
        onChange={handleLastNameChange}
        className="inputField"
      />
      {!lastNameValid && <p style={{color: 'red'}}>Input must contain only alphabetic characters.</p>}
      {/* <InputField
        type="text"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => setEmailAddress(e.target.value)}
        className="inputField"
      /> */}
      <div>
        <p style={{ marginTop: 10 }}>{FOOTER_MESSAGE}</p>
      </div>
    </div>
  );
};

export default NonUsIndividual;
