import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { AppContext } from "../../../Context/Context";
// Custom components
import Button from "../../../Components/Button/Button";
import { authentication } from "../../../Api/authentication";
// CSS & images
import "./OTP.css";
import Icon from "../../../Assets/Images/otpIcon1.png";
import { Routes } from "../../../Constant/Routes/Routes";
import Modal from "react-bootstrap/Modal";

interface OTPProp {
  handleToUpdate: () => void;
  stepLength: number;
  isEmail: boolean;
  existingEmail: string;
}

const OTP: React.FC<OTPProp> = ({handleToUpdate, stepLength, isEmail, existingEmail }) => {
  const { phone_Number, userId, setToken, loginEmail,isExistingUser } =
    React.useContext(AppContext);
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState(false);
  const [counter, setCounter] = React.useState(60);
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const history = useHistory();

  const push_token = "";
  const counterHandler = useCallback(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  React.useEffect(() => {
    if (stepLength === 3) {
      counterHandler();
    }
  }, [stepLength, counterHandler]);

  const ResendOtp = async () => {
    handleClose()
    const response = await authentication.signIn(phone_Number, loginEmail);
    if (response.status) {
      setError(false);
      setCode("");
      alert("OTP successfully sent!");
      setCounter(60);
    } else {
      alert("Something Went Wrong");
    }
    
  };

  const handleConfirmation = async () => {
    setError(false);
    setLoading(true);
    try {
      const response = await authentication.confirmation(
        userId,
        code,
        push_token
      );

      if (response.status) {
        setToken(response.message.token);
        localStorage.setItem("token", response.message.token);
        
        // this apis is not working
        // if (response.message.is_new_user) {
        //   await authentication.sendNewUserPhoneNumber({
        //     userId: userId,
        //     phoneNumber: phone_Number,
        //   });
        // }
        // slack email-----
        if (response.message.is_new_user) {
          await authentication.slack_email_notification(
            response.message.token,
            response.message.is_new_user,
            phone_Number,
            loginEmail
          );
        }
        setError(false);
        history.replace(Routes.PERSONAL_INFO_SCREEN);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  return (
    <div className="container">
      <div className="help_btn">
        <p onClick={handleShow}>Help</p>
      </div>
      <div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          backdrop="static"
          onHide={handleClose}
        >
          <Modal.Header className="bg-white border-0" closeButton>
            <Modal.Title> </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-white">
            <Button
              disabled={counter !== 0}
              label="RESEND PIN"
              className="p-3 mb-2 bg-primary text-white w-100"
              onClick={ResendOtp}
            />
            {/* <Button
              label="EDIT PHONE NUMBER"
              className="p-3 mb-2 bg-dark text-white w-100"
              onClick={handleToUpdate}
            /> */}
            {!isExistingUser?<Button
              label="EDIT EMAIL"
              className="p-3 mb-2 bg-dark text-white w-100"
              onClick={handleToUpdate}
            />:null}
            
          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>
      </div>
      <div className="header">
        <div className="mainHeader">
          <h4 className="mainTitle">Enter Verification Code</h4>
          <img src={Icon} alt="logo" />
        </div>
        <div className="otpMessageContainer">
          <h5 className="titleCode">
            Enter the code sent to your Email{" "}
            {/* {isEmail ? existingEmail || loginEmail : phone_Number} */}
          </h5>
        </div>
        <div className="otpContainer">
          <OtpInput
            value={code}
            onChange={setCode}
            inputStyle="inputFields"
            isInputNum
          />
        </div>
        {error ? (
          <h6 className="errorText">The code was not correct , try again</h6>
        ) : null}
        <div className="continueBtn">
          <Button
            label="Verify"
            className={code.length > 3 ? "activeButton" : "deactive-button"}
            disabled={code.length <= 3 || loading}
            onClick={handleConfirmation}
            icon={
              loading ? <i className="fas fa-circle-notch fa-spin"></i> : null
            }
          />
        </div>
        <div>
          <p className="footer">
            <button
              onClick={ResendOtp}
              disabled={counter !== 0}
              className={
                counter === 0 ? "otpQuestionTextenable" : "otpQuestionText"
              }
            >
              Didn’t recieve a code?
            </button>
            {counter !== 0 && "You can resend in"}
            <span className={counter !== 0 ? "footerSpan":"titleCode"}>
              {counter !== 0
                ? `00-${counter < 10 ? `0${counter}` : counter}`
                : "You can request a new code via the help menu."}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OTP;
