import React, { useState } from "react";
import SuccesLogo from "../../Assets/Images/newsuccess.png";
import { AccountSummary } from "../../Api/onBoarding";
import * as CgIcons from "react-icons/cg";
import * as IoIcons from "react-icons/io";

const Popup = (props) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function handlePopupContent(id) {
    if (id === "2") {
      setLoading(true);
      try {
        const data = { code };
        const res = await AccountSummary.validateCodeCreateCertificate(data);
        if (res.status) {
          props.setPopupContent(id);
          props.setReleaseStatus(true);
          setError("");
          // const data = {
          //   user_id: userId,
          // };
          // const response = await AccountSummary.updateCertificateReleaseStatus(
          //   data
          // );
          // if (response.status) {
          //   props.setCertificateContent(response);
          //   props.setPopupContent(id);
          //   setError("");
          // }
        } else {
          setError(
            "The code you entered is either invalid or has expired. Please reach out to the representative who provided the code or email us at ir@investwithcarl.com for further assistance."
          );
        }
      } catch (error) {
        setError(
          "The code you entered is either invalid or has expired. Please reach out to the representative who provided the code or email us at ir@investwithcarl.com for further assistance."
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else if (id === "4") {
      setLoading(true);
      try {
        const data = {};
        const res = await AccountSummary.sendSlackNotification(data);
        if (res.status) {
          props.setPopupContent(id);
          setError("");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      props.setPopupContent(id);
      setError("");
    }
  }

  return (
    <div className={props.className}>
      <div className="my_popup_view">
        {props.popupContent === "0" && (
          <div>
            <div
              className="my_popup_view_close"
              onClick={() => props.onClick("paymentpopup")}
            >
              <CgIcons.CgClose />
            </div>
            <h4>Do you have Verification code?</h4>
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <button
                className="my_popup_yes"
                onClick={() => handlePopupContent("1")}
              >
                Yes
              </button>
              <button
                className="my_popup_no"
                onClick={() => handlePopupContent("3")}
              >
                No
              </button>
            </div>
          </div>
        )}
        {props.popupContent === "1" && (
          <div>
            <div
              className="my_popup_view_back"
              onClick={() => handlePopupContent("0")}
            >
              <IoIcons.IoMdArrowRoundBack />
            </div>
            <div
              className="my_popup_view_close"
              onClick={() => props.onClick("paymentpopup")}
            >
              <CgIcons.CgClose />
            </div>
            <h4 className="text-center mt-5">Enter code to continue</h4>
            <div className="d-flex w-100 align-items-center justify-content-evenly ">
              <input
                type="text"
                placeholder="Enter Code"
                className="My_popup_view_input"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                  setError(""); // Clear error on new input
                }}
              />
            </div>
            {error && (
              <div style={{ width: "400px" }}>
                <p
                  style={{ color: "red", marginTop: -15, textAlign: "center" }}
                >
                  {`* ${error}`}
                </p>
              </div>
            )}
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <button
                className="popup_verify"
                onClick={() => handlePopupContent("2")}
                disabled={loading}
              >
                {loading ? "Verifying..." : "Verify"}
              </button>
            </div>
          </div>
        )}
        {props.popupContent === "2" && (
          <div>
            <div
              className="my_popup_view_close"
              onClick={() => props.onClick("paymentpopup")}
            >
              <CgIcons.CgClose />
            </div>
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <div className="popup_thanks text-center">
                <img src={SuccesLogo} alt="logo" className="w-50" />
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <h3 className="succesTitle">The code you enter is valid! </h3>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <h5 className="title">
                You can now access your Accredited Investor Certificate.
              </h5>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <button
                className="popup_Done"
                onClick={() => props.onClick("codepopup")}
              >
                OK
              </button>
            </div>
          </div>
        )}
        {props.popupContent === "3" && (
          <div className="my_popup_view_subscribe">
            <div
              className="my_popup_view_close"
              onClick={() => props.onClick("paymentpopup")}
            >
              <CgIcons.CgClose />
            </div>
            <p>
              To continue, request a secure payment link.
              <br />
              Upon receipt of your payment, you will gain access to retrieve
              your investor certificate, valid for a duration of two years.
              <br />
              The fee for this service is $68.
            </p>
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <button
                className="d-flex align-items-center rounded-pill w-75 my_popup_yes"
                onClick={() => handlePopupContent("4")}
                disabled={loading}
              >
                <div className="px-2 text-center w-100">
                  {loading ? "Requesting..." : "Request Secure Payment Link"}
                </div>
              </button>
            </div>
          </div>
        )}
        {props.popupContent === "4" && (
          <div className="my_popup_view_subscribe">
            <div
              className="my_popup_view_close"
              onClick={() => props.onClick("paymentpopup")}
            >
              <CgIcons.CgClose />
            </div>
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <div className="popup_thanks">
                <img src={SuccesLogo} alt="logo" />
              </div>
            </div>
            <p>
              You can expect to receive a payment link via email <br />
              within the next 24 hours. <br />
              Please be aware that it may take up to 48 hours for payments to be
              processed and reflected in our systems.
            </p>
            <div className="d-flex w-100 align-items-center justify-content-evenly">
              <button
                className="popup_ok"
                onClick={() => props.onClick("paymentpopup")}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
