import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SmallLog from "../../Assets/Images/logoIcon.png";
import "./Sidebar.css";
import * as FaIcons from "react-icons/fa";
import Header from "./Header";
import Popup from "./Popup";
import Modal from "./Modal"; // Import the modal
import { AccountSummary } from "../../Api/onBoarding";
import ProfilePop from "./ProfilePop";

const Sidebar = () => {
  const [inActive, setInActive] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("0");
  const [showButton, setShowButton] = useState(false);
  const [certificateContent, setCertificateContent] = useState({});
  const [userContent, setUserContent] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [releaseStatus, setReleaseStatus] = useState(false);
  const [profile, setProfile] = useState(false);

  const menuItems = [
    {
      name: "Account Summary",
      to: "/dashboard",
      exact: true,
      icon: <FaIcons.FaRegListAlt />,
    },
  ];

  function handlePopup(type) {
    if (type === "codepopup") {
      setShowButton(true);
    }
    setPopupContent("0");
    setAddPopup(!addPopup);
  }

  function handleMyProfile(){
    setProfile(true)
  }
  function handleMyProfiletoDashboard(){
    setProfile(false)
  }

  useEffect(() => {
    const getUserDetails = async () => {
      let user_id = localStorage.getItem("userId");
      try {
        const res = await AccountSummary.getCertificateUrl(user_id);
        if (res.status) {
          setCertificateContent(res);
          if (res?.message?.certificate_number !== undefined) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        }
      } catch (error) {
        setShowButton(false);
        console.error(error);
      }
      try {
        const res = await AccountSummary.getUser(user_id);
        if (res.status) {
            setUserContent(res);
        }
      } catch (error) {
        console.error(error);
        setShowButton(false);
      }
    };
    getUserDetails();
  }, [releaseStatus]);

  useEffect(() => {
    const isDisabled =
      !certificateContent?.message?.certificate_url || certificateContent?.message?.certificate_url === "";
    setDisabled(isDisabled);
  }, [certificateContent]);

  const handleView = () => {
    // Set the PDF URL and open the modal
    const pdfUrl = certificateContent?.message?.certificate_url; // Assuming this is the URL for the PDF
    setPdfUrl(pdfUrl);
    setIsModalOpen(true);
  };

  const handleDownload = () => {
    // Check if the certificate URL is available
    if (certificateContent?.message?.certificate_url) {
      const pdfUrl = certificateContent.message.certificate_url;
  
      // Open the PDF in a new tab
      const newWindow = window.open(pdfUrl, '_blank', 'noopener,noreferrer');
      
      // Check if the new tab was blocked by a popup blocker
      // if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
      //   alert('Please allow popups for this site to download the certificate.');
      // }
    } else {
      console.error("Certificate URL is not available.");
    }
  };
  
  
  return (
    <>
      <Header onClick={() => setInActive(!inActive)} handleProfile ={() => handleMyProfile()} userContent={userContent}/>
      <div
        className={`${inActive ? "navigation smallNav" : "navigation bigNav"}`}
      >
        <div style={{ paddingTop: "70px" }}>
          <ul>
            {menuItems.map((item, index) => (
              <li className="list" key={index}>
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  onClick={()=>handleMyProfiletoDashboard()}
                >
                  <span className="icon myIcon"> {item.icon}</span>
                  <span className="sidebar_title">{item.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={`${inActive ? "dashboard_small" : "dashboard_big"}`}>
        { profile ? <ProfilePop userContent={userContent}/>: <div className="px-4 p-2">
          <h3 className="text-white">
            <strong>Account Summary</strong>
          </h3>
          <div className="d-flex w-100 justify-content-between text-white tab_head_bg">
          <div className="p-1 px-5 active">
              Investor verification Document
              <span></span>
            </div>
          </div>
          <div className="tab_content">
            {showButton ? (
              <div className="row">
                <div className="col-7">
                  <p className="pe-3">
                    Congratulations! as an accredited investor, you have access
                    to exclusive opportunities to further your journey towards
                    financial independence and prosperity. <br />
                    The investor certificate serves as valid proof of your
                    status, allowing you to invest with confidence.
                    <br />
                    <br />
                    Press view or download below to retrieve your certificate
                  </p>
                </div>
                <div className="col-5">
                  <div className="row">
                    <div className="col-6 text-secondary">
                      <div>Certificate Number</div>
                      <div>Status</div>
                      <div>Expiration Date</div>
                    </div>
                    <div className="col-6">
                      <div>
                        : {certificateContent?.message?.certificate_number || "-"}
                      </div>
                      <div>
                        : {userContent?.message?.data?.investor_status
                          ?.accredited_investor_letter_status || "-"}
                      </div>
                      <div>
                        : {userContent?.message?.data?.investor_status?.expiration_date
                          ? new Date(
                              userContent?.message?.data.investor_status.expiration_date
                            ).toLocaleDateString()
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col-7">
                    <p>
                      Congratulations! as an accredited investor, you have
                      access to exclusive opportunities to further your journey
                      towards financial independence and prosperity. The
                      investor certificate serves as valid proof of your status,
                      allowing you to invest with confidence. How to retrieve
                      your certificate:
                    </p>
                    <ol>
                      <li> Press Continue and follow the instructions.</li>
                      <li>
                        {" "}
                        If you have a code, you will need this code to retrieve
                        your certificate.
                      </li>
                      <li>
                        {" "}
                        If you do not have a code, a secure link will be sent to
                        your email to finalize payment.
                      </li>
                    </ol>
                  </div>
                  <div className="col-5">
                    <div className="row">
                      <div className="col-6 text-secondary">
                        <div>Certificate Number</div>
                        <div>Status</div>
                        <div>Expiration Date</div>
                      </div>
                      <div className="col-6">
                        <div>
                          : {certificateContent?.message?.certificate_number || "-"}
                        </div>
                        <div>
                          : {userContent?.message?.data?.investor_status
                            ?.accredited_investor_letter_status || "-"}
                        </div>
                        <div>
                          : {userContent?.message?.data?.investor_status?.expiration_date
                            ? new Date(
                                userContent.data.investor_status.expiration_date
                              ).toLocaleDateString()
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showButton ? (
              <div className="tb_bttn">
                <div className="d-flex justify-content-evenly w-100">
                  <button
                    className="d-flex align-items-center rounded"
                    onClick={handleView}
                  >
                    <FaIcons.FaEye />
                    <div className="px-2">View</div>
                  </button>
                  <button
                    className="d-flex align-items-center rounded"
                    onClick={handleDownload}
                    disabled={disabled}
                  >
                    <FaIcons.FaDownload />
                    <div className="px-2">Download</div>
                  </button>
                </div>
              </div>
            ) : (
              <div className="tb_bttn">
                <button
                  className="d-flex align-items-center"
                  onClick={() => handlePopup()}
                >
                  <img src={SmallLog} />
                  <div className="px-2">Continue</div>
                </button>
              </div>
            )}
          </div>
        </div>
        
      }
      </div>
      <Popup
        className={`${"my_popup"} ${addPopup ? "" : "d-none"}`}
        onClick={(type) => handlePopup(type)}
        popupContent={popupContent}
        setPopupContent={setPopupContent}
        setReleaseStatus={setReleaseStatus}
      />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          content={pdfUrl}
          title={"Certificate"}
        />
      )}
      
    </>
  );
};

export default Sidebar;
