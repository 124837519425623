import React from "react";
import { useHistory } from "react-router-dom";

import Button from "../../../Components/Button/Button";
import PrivacyLogo from "../../../Assets/svg/PrivacyLogo";
import { DWOLLA_TOS, DWOLLA_PP, CARL_TOS_PP } from "../../../Constant";
import { Info } from "../../../Api/onBoarding";
import { AppContext } from "../../../Context/Context";
import "./style.css";
import { ApplicationStatus } from "../../../Constant/onboardingData";
import { Routes } from "../../../Constant/Routes/Routes";

const Termsandcondition = ({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) => {
  const { userId, applicationStatus } = React.useContext(AppContext);

  const [loading, setLoading] = React.useState(false);
  const [t_CCheckbox, setT_CCheckbox] = React.useState(false);
  const history = useHistory();
  const checkUserFlow = () => {
    if (applicationStatus === ApplicationStatus.PENDING) {
      history.replace(Routes.HOME_SCREEN);
    }
  };

  checkUserFlow();

  const handleSubmit = async () => {
    setLoading(true);
    const {
      message: {
        data: {
          personalinfodata: { first_name, last_name, email },
        },
      },
    } = await Info.GetUserDetails();
    try {
      const data = {
        first_name,
        last_name,
        email,
      };

      await Info.submitUserDataToCARL(data, userId);
      handleToUpdate();
    } catch (error: any) {      
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="header">
        <div className="headerContainer">
          <h2 className="title">Your application is ready</h2>
        </div>
        <div className="logoContainer">
          <PrivacyLogo />
        </div>
        <div className="termsContainer">
          {" "}
          <div className="agreeTermsContainer">
            <div className="inputContainer">
              <input
                name="options"
                type="radio"
                className="input"
                id="link"
                onChange={() => setT_CCheckbox(true)}
              />
              <label htmlFor="link" className="labelText">
                I have read and agree to{" "}
                <a
                  className="termLink"
                  href={DWOLLA_PP}
                  target="_blank"
                  rel="noreferrer"
                >
                  CARL Terms of Service and Privacy Policy
                </a>
                , as well as our partner{" "}
                <a
                  className="termLink"
                  href={DWOLLA_TOS}
                  target="_blank"
                  rel="noreferrer"
                >
                  Dwolla Terms of Service
                </a>{" "}
                and{" "}
                <a
                  className="termLink"
                  href={CARL_TOS_PP}
                  target="_blank"
                  rel="noreferrer"
                >
                  Dwolla Privacy Policy
                </a>
                .
              </label>
            </div>
          </div>
        </div>
        <Button
          label="Agree & Submit my application"
          className={t_CCheckbox ? "TermsButton" : "deactiveButton"}
          icon={
            loading ? (
              <i className="fas fa-circle-notch fa-spin mt-2"></i>
            ) : null
          }
          onClick={handleSubmit}
          disabled={!t_CCheckbox}
        />
      </div>
    </div>
  );
};
export default Termsandcondition;
